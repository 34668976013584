export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavDropdown',
        name: 'รายการหวย',
        route: '/manage-lotto',
        icon: 'cil-list',
        items: [
          {
            name: 'หวยประจำวัน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/daily'
          },
          {
            name: 'รอออกผล',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/wait-result'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'จัดการหวย',
        route: '/lotto-setting',
        fontIcon: 'fas fa-cogs',
        items: [
          {
            name: 'ตั้งค่างวดหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/setting'
          },
          {
            name: 'เลขจ่ายครึ่ง',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/pay-half'
          },
          {
            name: 'เลขปิดรับ',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/close-numbers'
          },
          {
            name: 'ออกผล',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/award'
          },
          {
            name: 'จ่ายเงิน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/pay'
          }
        ]
      }
    ]
  }
]
